

const quotes = [
    {
        id: 1,
        quote: "There is a God but you're not him."
    },
    {
        id: 2,
        quote: "That in All things God may be Glorified"
    }, 
    {
        id: 3,
        quote: "With God Everything is Possible"
    }, {
        id: 4,
        quote: "Jesus is the way, the truth and the life"
    }, {
        id: 5,
        quote: " If my people, which are called by my name, shall humble themselves, and pray, and seek my face, and turn from their wicked ways; then will I hear from heaven, and will forgive their sin, and will heal their land"
    }
]


export {
    quotes
}
