import React from "react";
import { quotes } from "../assets/quotes";

export default function MainView(props) {

    return(
    
        <>
            {quotes.map( (q,i) => (
                <React.Fragment>
                    {i <= 4 && (
                        <div style={{fontFamily: 'Great Vibes, cursive', fontSize: '30px', margin: "10px"}} >
                            {q.quote}
                        </div>
                    )}
                </React.Fragment>
            ))}



        </>
    )
}
