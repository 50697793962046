import logo from './logo.svg';
import './App.css';
import MainView from './components/main';

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Coming Soon!
        </p>

      </header> */}
      <MainView />
    </div>
  );
}

export default App;
